.centerXY {
    top:50%;
    left:50%;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.centerX {
    left:50%;
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}
.centerY {
    top:50%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

@mixin hover{
    @media(hover:hover){
      &:hover{
        @content;
      }
    }
    &:active{
      @content;
    }
}