@font-face {
  font-family: "Neue Power";
  src: url("../assets/fonts/NeuePower-Medium.eot");
  src: url("../assets/fonts/NeuePower-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/NeuePower-Medium.woff2") format("woff2"),
    url("../assets/fonts/NeuePower-Medium.woff") format("woff"),
    url("../assets/fonts/NeuePower-Medium.ttf") format("truetype"),
    url("../assets/fonts/NeuePower-Medium.svg#NeuePower-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

html,
body {
  // font-family: 'Neue Power', sans-serif;
  font-family: "Helvetica", sans-serif;
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit;
  text-decoration: none;
  @include hover {
    text-decoration: none;
  }
}
h1 {
  margin-bottom: 20px;
  width: 100%;
  font-size: 8vw;
  line-height: 6.5vw;
  text-transform: uppercase;
  -webkit-text-stroke-width: 5px;
  -webkit-text-stroke-color: #d1d1d1;
  // text-shadow: 0 0 20px #fff;
  letter-spacing: -0.4vw;
  font-weight: 800;
}

h2 {
  font-family: 'Neue Power', sans-serif;
  margin-bottom: 20px;
  width: 100%;
  font-weight: normal;
}

h3 {
  font-family: 'Neue Power', sans-serif;
  font-size: 4vw;
  line-height: 3vw;
  margin-bottom: 20px;
  width: 100%;
  font-weight: normal;
}


em {
  font-style: italic;
}

strong {
  font-weight: bold;
}


@include mobile {
  h1 {
    font-size: 15vw;
    line-height: 12vw;
    letter-spacing: -0.1vw;
    -webkit-text-stroke-width: 2px;
  }

  h3 {
    font-size: 20px;
    line-height: 24px;
  }
}