*::selection {
  background: rgba(255, 255, 255, 0.99);
  color: var(--purple);
}
*::-moz-selection {
  background: rgba(255, 255, 255, 0.99);
  color: var(--purple);
}

*::-webkit-scrollbar {
  width: 0px;
  display: none;
}
*::-webkit-scrollbar-track {
  background-color: var(--purple);
  display: none;
}

*::-webkit-scrollbar-thumb {
  background-color: #000;
  border: none;
}



html,
body {
  width: 100%;
  height: 100%;
  color: #FFF;
  background-color: #000;
}

.outer-wrapper{
  width: 100%;
  height: var(--app-height);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
}

.logo{
  height: 10vw;
  width: auto;
  display: block;
  margin: 0 auto;
}
.mailchimp{

  &__wrapper{
    width: 40%;
    text-align: center;
    margin: 0 auto;
  }
}
@include mobile{
  .mailchimp{

    &__wrapper{
      width: 80%;
      text-align: center;
      margin: 0 auto;
    }
  }
}

input[type=email]{
  width: calc(100% - 20px);
  background-color: #FFF;
  padding: 10px;
  border: none;
  box-shadow: none;
  margin-bottom: 20px;
  border-radius: 5px;
  font-family: inherit;
}


input[type=submit]{
  background-color: #FFF;
  padding: 10px;
  border: 1px solid #FFF;
  box-shadow: none;
  margin-bottom: 20px;
  border-radius: 5px;
  font-family: inherit;
  text-transform: uppercase;
  color: #000;
  cursor: pointer;
  font-family: 'Neue Power', sans-serif;
  @include hover{
    background-color: #000;
    color: #FFF;
  }
}

@include mobile {

  .outer-wrapper{
    display: block;
  }

  .section-wrapper{
    padding: 20px;
  }

  .logo{
    height: auto;
    width: 80%;
  }

}