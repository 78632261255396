@mixin tablet{
	@media screen and (max-width:1000px) and (min-width:751px){
		@content;
	}
}

@mixin mobile{
	@media screen and (max-width:750px) and (min-width:1px){
		@content;
	}
}

@mixin about{
	@media screen and (max-width:950px) and (min-width:1px){
		@content;
	}
}